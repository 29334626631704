import { Popover } from "@headlessui/react";
import EmojiPicker, { IEmojiPickerProps } from "emoji-picker-react";
import { Button } from "src/components/Elements";
import React from "react";

import { EmojiPickerIcon } from "@assets/Icons";

type Props = IEmojiPickerProps;

export const EmojiPopover = ({ onEmojiClick }: Props) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as={Button} view="none" className="flex">
            <EmojiPickerIcon />
          </Popover.Button>
          {open && (
            <Popover.Panel className="absolute z-10 right-0 mt-2 bg-white border shadow-lg p-0 rounded">
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};
