import { Flexbox } from "src/components/Elements";
import React from "react";

import { SvgIconComponentType } from "@common/Types";
import { decimal } from "@common/Utils";

type Props = {
  label: string;
  value: number | undefined;
  icon: SvgIconComponentType;
  counter: string;
};
export const TotalCard: React.FC<Props> = ({
  label,
  value,
  icon: Icon,
  counter,
}) => {
  return (
    <Flexbox
      flexDirection="flex-col"
      bgColor="neutral-0"
      className="w-full rounded-md px-3 py-2 border border-gray-300"
    >
      <div className="my-3">
        <div
          className={
            "bg-gray-100 rounded w-7 h-7 flex items-center justify-center"
          }
        >
          <Icon />
        </div>
      </div>

      <div>
        <h2 className="text-gray-400 text-sm">{label}</h2>
        <span className="text-xl font-semibold">
          {(value && decimal(value)) || 0}
        </span>
        <span className="bold">{counter}</span>
      </div>
    </Flexbox>
  );
};
