import { Car, Coffee, LineSegments, X } from "phosphor-react";
import { Col, Row, Typography } from "src/components/Elements";
import React from "react";

const statuses = {
  online: {
    label: "На линии",
    icon: X,
  },
  on_ride: {
    label: "В пути",
    icon: Car,
  },
  busy: {
    label: "Занят",
    icon: LineSegments,
  },
  offline: {
    label: "Оффлайн",
    icon: Coffee,
  },
};

type Props = {
  status: string;
  value?: number;
};

export const DriverStatusCard = ({ status, value = 0 }: Props) => {
  const statusDetails = statuses[status as keyof typeof statuses];

  if (!statusDetails) {
    return null;
  }
  const { icon: Icon, label } = statusDetails;

  return (
    <Col
      alignItems="items-center"
      justifyContent="justify-center"
      className="p-5 gap-2 bg-neutral-bg-0 border rounded-md w-full"
    >
      <Row
        className="gap-2"
        alignItems="items-center"
        justifyContent="justify-center"
      >
        <Icon />
        <Typography color="neutral-800" className="font-medium">
          {label}
        </Typography>
      </Row>
      <Typography
        as="h3"
        color="neutral-800"
        variant="heading-1-a"
        className="font-medium"
      >
        <span className="text-4xl">{value}</span>
      </Typography>
    </Col>
  );
};
