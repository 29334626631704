import clsx from "clsx";
import { Row } from "src/components/Elements";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface InfiniteScrollComponentProps<T> {
  data: T[];
  fetchItems: (page: number) => void;
  ItemComponent: React.FC<{ dataItem: T; prevDataItem: T; itemIndex: number }>;
  hasMore: boolean;
  isLoading?: boolean;
  reversed?: boolean;
  limit?: number;
  className?: string;
  loaderComponent?: React.ReactElement | null;
  endMessageComponent?: React.ReactElement;
  hidden?: boolean;
}

export const InfiniteScrollComponent = <T,>({
  data,
  fetchItems,
  ItemComponent,
  limit = 16,
  hasMore,
  isLoading = false,
  reversed = false,
  hidden = false,
  className = "",
  loaderComponent,
  endMessageComponent,
}: InfiniteScrollComponentProps<T>) => {
  const [page, setPage] = useState(limit);

  const fetchData = () => {
    fetchItems(page);

    if (hasMore) {
      setPage((prevPage) => prevPage + 6);
    }
  };

  useEffect(() => {
    if (data.length === 0 && !isLoading) {
      fetchData();
    }
  }, []);

  const displayedData = React.useMemo(() => {
    return reversed ? [...data].reverse() : data;
  }, [data]);

  if (hidden) {
    return null;
  }

  return (
    <div id="scrollableDiv" className="w-full h-full relative overflow-hidden">
      <InfiniteScroll
        next={fetchData}
        hasMore={hasMore}
        dataLength={data.length}
        scrollableTarget="scrollableDiv"
        loader={
          <Row
            className={`w-full h-full  justify-center absolute ${
              reversed ? "top-3 items-start" : "bottom-3 items-end"
            }`}
          >
            {loaderComponent}
          </Row>
        }
        endMessage={endMessageComponent}
        className={clsx("p-3", className)}
        inverse={reversed}
        height="100%"
        style={{
          display: "flex",
          flexDirection: reversed ? "column-reverse" : "column",
        }}
      >
        {displayedData.map((item, index) => (
          <ItemComponent
            key={index}
            dataItem={item}
            itemIndex={index}
            prevDataItem={displayedData[index - 1]}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
