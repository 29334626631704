import { Autocomplete, AutocompleteItem, Group } from "@mantine/core";
import { FieldInputProps } from "formik";
import { Typography } from "src/components/Elements";
import React, { forwardRef } from "react";

export interface OptionItem {
  value: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, OptionItem>(
  ({ label, description, ...others }: OptionItem, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div className="grid grid-cols-1">
          <Typography color={"neutral-900"}>{description}</Typography>
          <Typography variant="caption-b" color={"neutral-500"}>
            {label}
          </Typography>
        </div>
      </Group>
    </div>
  ),
);

type Props = {
  data: AutocompleteItem[];
  label: string;
  placeholder: string;
  name: string;
  field: FieldInputProps<any>;
  hasError: any;
  error?: string;
  value: any;
  onChange: (value: string) => void;
  onItemSubmit: (item: AutocompleteItem) => void;
};

export const AutoCompleteInput = ({
  data,
  label,
  placeholder,
  field,
  hasError,
  error,
  onChange,
  ...rest
}: Props) => {
  return (
    <div>
      <Autocomplete
        {...field}
        {...rest}
        label={label}
        data={data}
        limit={15}
        itemComponent={SelectItem}
        placeholder={placeholder}
        maxDropdownHeight={300}
        onChange={onChange}
        filter={(value, item) =>
          item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.description.toLowerCase().includes(value.toLowerCase().trim())
        }
        classNames={{
          input:
            "h-[42px] border border-neutral-border-300 placeholder:text-body-1-a ring-0 ring-transparent",
          hovered: "bg-brand-bg-0",
          label: "!text-caption-b",
        }}
      />
      {hasError ? (
        <p className="text-negative-bg-500 text-caption-a mt-[5px]">{error}</p>
      ) : null}
    </div>
  );
};
