import { DashboardLayout } from "src/components/Layouts";
import React from "react";

import { NAVIGATE_ROUTES } from "@common/Constants";

import { ProtectedRoute } from "../protected-route";

type DashboardRouteProps = {
  element: React.ReactNode;
};

export const DashboardRoute = ({ element }: DashboardRouteProps) => {
  return (
    <ProtectedRoute
      notFoundPath={NAVIGATE_ROUTES.DASHBOARD}
      element={<DashboardLayout>{element}</DashboardLayout>}
    />
  );
};
