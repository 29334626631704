import { Image } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useField } from "formik";
import { Col, Typography } from "src/components/Elements";
import React, { ReactNode, useState } from "react";

import { useUploadImage } from "@api/queries";
import { cn } from "@common/Utils";

import { validateImage } from "../../SharedFeature/settings/common/utils";

type Props = {
  field: string;
  label?: string;
  children: ReactNode;
  oldImage?: string | null;
  className?: string;
  ios?: "6" | "5";
  accept: string[];
  maxSize?: number;
  error?: ReactNode;
  loading?: boolean;
  required?: boolean;
  onLoading?: (loading: boolean) => void;
};

const iosDimensions: Record<string, number[][]> = {
  "6": [
    [1242, 2688],
    [1284, 2778],
  ],
  "5": [[1242, 2207]],
};

export const ImageUpload = ({
  field,
  label,
  children,
  oldImage,
  className,
  ios,
  accept,
  maxSize = 5242880, // 5MB
  error,
  loading = false,
  required = false,
  onLoading,
}: Props) => {
  const [previewImage, setPreviewImage] = useState(oldImage);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { mutateAsync: uploadImage } = useUploadImage();

  const { setValue } = useField(field)[2];

  const handleImagePreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      setPreviewImage(dataURL?.toString() ?? "");
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async (files: File[]) => {
    onLoading?.(true);
    try {
      const file = files[0];
      handleImagePreview(file);
      await validateImage(file, iosDimensions[ios || ""]);

      setErrorMsg("");

      const response = await uploadImage(file);
      setValue(response.id);
      onLoading?.(false);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        setErrorMsg(error.message);
      }
    }
  };

  return (
    <div className="space-y-2 flex-1 w-full">
      <div className="space-y-1">
        {label && (
          <Typography
            color="neutral-800"
            variant="caption-b"
            className="font-medium"
          >
            {label}

            {required && <span className="text-error ml-1">*</span>}
          </Typography>
        )}

        <Dropzone
          onDrop={handleImageUpload}
          maxSize={maxSize}
          accept={accept}
          className={cn(
            "overflow-hidden",
            { "border-negative-border-500": error },
            className,
          )}
          loading={loading}
        >
          {() =>
            previewImage ? (
              <Col
                alignItems="items-center"
                justifyContent="justify-center"
                className="text-center gap-2 overflow-hidden h-full"
              >
                <Image
                  src={previewImage ?? ""}
                  classNames={{ image: "max-h-80" }}
                  fit="contain"
                  alt="preview"
                />
              </Col>
            ) : (
              children
            )
          }
        </Dropzone>
      </div>

      {error && (
        <Typography color="negative-500" variant="caption-a" as="p">
          {error || "Загрузите интро"}
        </Typography>
      )}

      {errorMsg && (
        <Typography color="negative-500" variant="caption-a" as="p">
          {errorMsg}
        </Typography>
      )}
    </div>
  );
};
