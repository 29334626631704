import { Row } from "src/components/Elements";
import React from "react";
import { Marker } from "react-map-gl";

import { MapDriver } from "@common/Types";

import { PinIcon } from "../../common/assets/icons";

type Props = {
  driver: MapDriver;
  status: string;
  setDriverInfo: (driver: MapDriver) => void;
};

export const Pins = ({ driver, status, setDriverInfo }: Props) => {
  const handleChoosePin = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setDriverInfo(driver);
  };

  return (
    <Marker
      latitude={parseFloat(driver.lat)}
      longitude={parseFloat(driver.lon)}
    >
      <Row
        className="relative w-[1px] h-[1px]"
        alignItems="items-center"
        justifyContent="justify-center"
      >
        <div className="absolute bottom-[-4px]">
          <PinIcon handleClick={handleChoosePin} status={status} />
        </div>
      </Row>
    </Marker>
  );
};
