import { Field, useField } from "formik";
import { isArray } from "radash";
import {
  Col,
  NumberInput,
  Select,
  TextInput,
  TimeInput,
  Typography,
} from "src/components/Elements";
import React from "react";

import { CommonTypedFieldParams } from "@common/Types";
import { cn } from "@common/Utils";

import { renderTextValue } from "./common/utils";

const getComponentByType = <T,>(type: CommonTypedFieldParams<T>["type"]) => {
  switch (type) {
    case "time": {
      return TimeInput;
    }
    case "number": {
      return NumberInput;
    }
    case "select": {
      return Select;
    }
    case "text": {
      return TextInput;
    }
  }
};

type Props<T> = {
  params: CommonTypedFieldParams<T>[] | CommonTypedFieldParams<T>;
  isEdit?: boolean;
};

export const FormDescriptionField = <T,>({
  params,
  isEdit = false,
}: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map(
        (param: CommonTypedFieldParams<T>, index: number) => {
          const {
            name,
            field,
            type,
            customValue = null,
            disabled = false,
            description,
            placeholder,
            show = true,
            ...rest
          } = param;

          const [{ value: fieldValue, onChange }, { touched, error }, helper] =
            useField(String(field));

          const currentValue = customValue ?? fieldValue;

          const handleDescriptionChange = (value: MouseEvent & Date) => {
            switch (type) {
              case "text": {
                return onChange(value);
              }
              case "time": {
                const { onTimeChange } = param;

                return onTimeChange ? onTimeChange(value) : onChange(value);
              }
              case "number":
              case "select": {
                return helper.setValue(value);
              }
            }
          };

          if (!show) return null;

          return (
            <Col
              key={`${String(name)}-${index}`}
              className={cn(
                "gap-1 flex-wrap min-h-[48px]",
                rest.wrapperClassName,
              )}
            >
              {!isEdit ? (
                <>
                  <Typography
                    color="neutral-500"
                    variant="body-2-b"
                    as="dt"
                    className="font-medium"
                  >
                    {name}
                  </Typography>

                  <Typography color="neutral-800" as="dd">
                    {renderTextValue({
                      param,
                      currentValue,
                      fieldValue,
                    })}
                  </Typography>
                </>
              ) : (
                <Col
                  className="gap-3 w-full h-full"
                  justifyContent="justify-between"
                >
                  {description && (
                    <Typography
                      as="span"
                      color="neutral-500"
                      variant="body-2-a"
                      className="font-medium text-wrap"
                    >
                      {description}
                    </Typography>
                  )}

                  <Field
                    as={getComponentByType(type)}
                    value={currentValue}
                    name={field}
                    label={name}
                    placeholder={placeholder || "Введите значение"}
                    disabled={disabled}
                    error={(touched && error) || null}
                    onChange={handleDescriptionChange}
                    {...rest}
                  />
                </Col>
              )}
            </Col>
          );
        },
      )}
    </>
  );
};
