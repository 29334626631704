import {
  NumberInput as MantineNumberInput,
  NumberInputProps,
} from "@mantine/core";
import { Field, useField } from "formik";
import { Col, Row, Typography } from "src/components/Elements";
import React from "react";

import { Props } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * A customizable number input component.
 *
 * This component wraps Mantine's `NumberInput` and applies custom styles based on the `view` prop.
 * It handles different states such as `disabled` and `error`, and allows additional styling through classNames.
 *
 * @param {Props} props - The properties for the NumberInput component.
 * @returns {React.FC} - The NumberInput component.
 */

export const NumberInput: React.FC<Props> = ({
  children,
  view = "default",
  label,
  error,
  required,
  disabled,
  fullWidth = false,
  className,
  ...props
}) => {
  return (
    <Col className="w-full gap-[4px]">
      {label && (
        <Row alignItems="items-center" className="inline-flex">
          <Typography className={VIEW_CLASSES[view].label} as="label">
            {label}

            {required && <span className="text-error ml-1">*</span>}
          </Typography>
        </Row>
      )}
      <MantineNumberInput
        hideControls
        error={error}
        classNames={{
          icon: "mr-[6px]",
          ...VIEW_CLASSES[view],
        }}
        {...props}
      >
        {children}
      </MantineNumberInput>
    </Col>
  );
};

export const FormNumberInput = ({
  field,
  ...rest
}: NumberInputProps & { field: string }) => {
  const [fieldProps, { touched, error }] = useField(field);

  return (
    <Field
      as={NumberInput}
      error={touched && error}
      {...fieldProps}
      {...rest}
    />
  );
};
