import { DatePicker as MantinePicker } from "@mantine/dates";
import { format } from "date-fns";
import { useField, useFormikContext } from "formik";
import { Col, Typography } from "src/components/Elements";
import React from "react";

import { CalendarIcon } from "@assets/Icons";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_yyyy_MM_dd,
} from "@common/Constants";
import { formatDate } from "@common/Utils";

type MantineProps = {
  name: string;
  error?: string;
  touched?: boolean;
  defaultDate?: Date | string | null;
  label?: string;
  placeholder?: string;
  required?: boolean;
  clearable?: boolean;
  isEditable?: boolean;
  labelFormat?: string;
  inputFormat?: string;
};

export const DatePicker: React.FC<MantineProps> = ({
  error,
  touched,
  defaultDate,
  label,
  inputFormat = DATE_FORMAT_YYYY_MM_DD,
  labelFormat = DATE_FORMAT_YYYY_MM_DD,
  clearable = false,
  isEditable = true,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const hasError = error && touched;

  if (isEditable) {
    return (
      <MantinePicker
        required
        zIndex={1000}
        {...props}
        {...field}
        value={field.value ? new Date(field.value) : null}
        classNames={{
          label: "text-xs flex items-center gap-[4px]",
          input:
            "h-[40px] text-neutral-text-800 text-body-1-a placeholder-neutral-text-400 border border-neutral-border-300 w-full focus:outline-none focus:ring-1 focus:ring-blue-600",
          error: "hidden",
        }}
        label={
          <Typography
            as="span"
            variant={"caption-b"}
            color={"neutral-800"}
            className="font-medium"
          >
            {label}
          </Typography>
        }
        locale="ru"
        allowFreeInput={true}
        defaultValue={defaultDate ? new Date(defaultDate) : null}
        clearable={clearable}
        inputFormat={inputFormat}
        labelFormat={labelFormat}
        icon={<CalendarIcon />}
        onChange={(val: Date) => {
          return (
            val &&
            setFieldValue(field.name, format(val, DATE_FORMAT_yyyy_MM_dd))
          );
        }}
        error={
          hasError ? <p className="text-red-500 text-sm">{error}</p> : undefined
        }
      />
    );
  }

  return (
    <Col className="gap-1 flex-wrap min-h-[48px]">
      <Typography
        color="neutral-500"
        variant="body-2-b"
        as="dt"
        className="font-medium"
      >
        {label}
      </Typography>

      <Typography color="neutral-800" as="dd">
        {formatDate(new Date(field.value), inputFormat.toLowerCase())}
      </Typography>
    </Col>
  );
};
