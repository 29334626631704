import { Group, Pagination as MantinePagination, Space } from "@mantine/core";
import { Row, Typography } from "src/components/Elements";
import { CustomSelect } from "src/components/Shared/filters/components/custom-select";
import React from "react";

import { PaginationProps } from "./types";

export const Pagination: React.FC<PaginationProps> = ({
  skip,
  setSkip,
  limit,
  setLimit,
  limitData,
  length,
  position,
  showCount,
}) => {
  const page = skip / limit + 1;

  const onChange = (page: number) => {
    setSkip((page - 1) * limit);
  };

  if (!length || length <= limit) {
    return null;
  }

  return (
    <>
      <Space h={"md"} />
      <Row
        justifyContent={"justify-between"}
        alignItems={"items-center"}
        className={"w-full"}
      >
        <Group position={position ?? "right"}>
          <MantinePagination
            total={Math.ceil(length / limit)}
            page={page}
            onChange={onChange}
            size="md"
            classNames={{
              active: "bg-brand-bg-1000",
            }}
            withEdges
          />
        </Group>
        {showCount && (
          <Row alignItems={"items-center"} className={"gap-[16px]"}>
            <Typography variant={"body-2-a"} className={"text-black"}>
              Отобразить на странице:
            </Typography>
            <CustomSelect
              value={String(limit)}
              data={limitData ?? []}
              onChange={(value) => setLimit?.(Number(value))}
              className={"max-w-[64px]"}
            />
          </Row>
        )}
      </Row>
    </>
  );
};
