import { useFormikContext } from "formik";
import {
  GroupedMultiSelect,
  MultipleChoiceDataType,
} from "src/components/Elements";
import React from "react";

import { useDelay, useDimension } from "@common/Hooks";

import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";

type Props<T> = {
  field: keyof T;
  options: MultipleChoiceDataType[];
  label?: string;
  placeholder?: string;
  icon?: React.ElementType;
};

export const MultiSelectFilter = <T,>({
  label = "Выберите",
  placeholder = "Выберите",
  icon: Icon,
  field,
  options,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();

  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const handleMultipleSelection = (selected: MultipleChoiceDataType[]) => {
    const selectedValues = selected.map((option) => option.value);
    const labels = selected.map((option) => option.label).join(",");
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, labels);

    setValues({
      ...values,
      chips: updatedChips,
      [field]: selectedValues,
    });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  return (
    <GroupedMultiSelect
      data={options}
      placement={"start"}
      selectedValues={values[field] as unknown as string[]}
      handleSelect={handleMultipleSelection}
      inputProps={{
        label: isDesktop ? "" : label,
        icon: Icon ? <Icon /> : null,
        placeholder,
      }}
      {...props}
    />
  );
};
