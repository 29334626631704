import { Flexbox, Grid, Typography, WidthUnits } from "src/components/Elements";
import React from "react";

import { ColorClassKey, CommonTypedFieldParams } from "@common/Types";

import { FormDescriptionField } from "../form-description-field";

type Props<T> = {
  title?: string;
  params: CommonTypedFieldParams<T>[] | CommonTypedFieldParams<T>;
  isEdit?: boolean;
  bgColor?: ColorClassKey;
  width?: WidthUnits;
};

export const Limits = <T,>({
  title,
  params,
  isEdit = false,
  bgColor = "neutral-50",
  width = "238px",
}: Props<T>) => {
  return (
    <Flexbox flexDirection="flex-col" className="gap-2">
      {title && (
        <Typography
          color="neutral-800"
          variant="body-2-b"
          className="font-medium"
        >
          {title}
        </Typography>
      )}

      <Grid
        columns="auto-fill"
        minColumnWidth={width}
        bgColor={bgColor}
        className="w-full py-2 px-4 rounded-lg"
      >
        <FormDescriptionField isEdit={isEdit} params={params} />
      </Grid>
    </Flexbox>
  );
};
