import { Flexbox, Row, Typography } from "src/components/Elements";
import React, { ReactNode } from "react";

import { FontVariant, SvgIconComponentType } from "@common/Types";
import { cn } from "@common/Utils";

import { BlockView } from "./types";
import { VIEW_CLASSES } from "./variants";

type Props = {
  children: ReactNode;
  title?: string;
  view?: BlockView;
  Icon?: SvgIconComponentType;
  className?: string;
  titleVariant?: FontVariant;
  renderRowItem?: JSX.Element;
  renderRightItem?: JSX.Element | null;
  fullWidth?: boolean;
  onClickTitle?: () => void;
};

export const Block = ({
  children,
  Icon,
  view = "default",
  title,
  className,
  titleVariant = "heading-2-b",
  renderRowItem,
  renderRightItem,
  fullWidth = false,
  onClickTitle,
}: Props) => {
  return (
    <div className={cn(VIEW_CLASSES[view], className, { "w-full": fullWidth })}>
      {title && (
        <Flexbox
          justifyContent="justify-between"
          alignItems="items-center"
          className="gap-2"
        >
          <Row alignItems="items-center" className="gap-4 flex-wrap">
            {Icon && <Icon />}

            <Typography
              color="neutral-800"
              variant={titleVariant}
              as="h2"
              className={cn(
                { "cursor-pointer": onClickTitle },
                "font-semibold",
              )}
              onClick={onClickTitle}
            >
              {title}
            </Typography>

            {renderRowItem}
          </Row>

          {renderRightItem}
        </Flexbox>
      )}

      {children}
    </div>
  );
};
