import { useFormikContext } from "formik";
import { Row, Typography } from "src/components/Elements";
import React, { useEffect } from "react";

import { CloseIcon } from "@assets/Icons";

import { FilterChipType } from "../common/types";

export const ChipsFilter = () => {
  const { values, setFieldValue } = useFormikContext<{
    chips: FilterChipType[];
  }>();
  const chips = values?.chips || [];
  const hasChips = !!chips.length;

  const handleRemove = (chip: FilterChipType) => {
    const filteredValues = chips.filter(({ field }) => field !== chip.field);

    setFieldValue("chips", filteredValues);
    setFieldValue(chip.field, null);
  };

  useEffect(() => {
    return () => handleRemoveAll();
  }, []);

  const handleRemoveAll = () => {
    for (const chip of chips) {
      setFieldValue(chip.field, "");
    }

    setFieldValue("chips", null);
  };

  if (hasChips) {
    return (
      <Row className="gap-1">
        {chips.map((chip) => {
          const list = chip.list.join(",");
          return (
            <Row
              alignItems="items-center"
              bgColor="neutral-75"
              className="border rounded-s p-1 gap-1"
              justifyContent="justify-center"
              key={chip.field}
              onClick={() => handleRemove(chip)}
            >
              <Typography variant="caption-a" color="neutral-800">
                {list}
              </Typography>
              <CloseIcon size={15} />
            </Row>
          );
        })}
        <Row
          alignItems="items-center"
          bgColor="neutral-75"
          className="border rounded-s p-1 gap-1"
          justifyContent="justify-center"
          onClick={handleRemoveAll}
        >
          <Typography variant="caption-a" color="neutral-800">
            Сбросить
          </Typography>
        </Row>
      </Row>
    );
  }

  return null;
};
