import { Button, Group, Select, SelectProps, Space } from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import { parse } from "date-fns";
import { Modal } from "src/components/Elements";
import React, { useEffect, useState } from "react";

import { DATE_FORMAT_dd_MM_yyyy } from "@common/Constants";
import { useDimension } from "@common/Hooks";
import {
  DateRageFilterType,
  PredefinedDateRangeType,
  PredefinedRanges,
} from "@common/Types";
import { formatDate } from "@common/Utils";

import { DateRangeType } from "./types";
import { isDateStringRange } from "./utils";

type Props = {
  maxDate?: Date;
  minDate?: Date;
  format?: string;
  onChangeDate: (dateRange: DateRageFilterType) => void;
  onChangeSelect: (selectRange: PredefinedRanges) => void;
  onPredefinedChange?: (value: PredefinedDateRangeType) => void;
  dateRange?: DateRageFilterType;
  className?: string;
  predefinedRanges: PredefinedRanges[];
  defaultSelected?: PredefinedRanges;
  inputComponent?: React.ElementType;
};

type DefaultSelectProps = {
  data: SelectProps["data"];
  value: SelectProps["value"];
  onChange: (value: PredefinedDateRangeType) => void;
};

const DefaultSelect = ({ onChange, data, value }: DefaultSelectProps) => {
  return (
    <Select
      data={data}
      value={value}
      onChange={onChange}
      className="h-[40px] w-[240px] text-neutral-text-800 font-normal text-body-1-a rounded-[4px] bg-neutral-bg-50"
      classNames={{ input: "bg-[#FAFAFA] text-body-1-a h-[40px]" }}
    />
  );
};

export const DateRangeSelect: React.FC<Props> = ({
  format = DATE_FORMAT_dd_MM_yyyy,
  dateRange,
  onChangeDate,
  onChangeSelect,
  onPredefinedChange,
  predefinedRanges,
  minDate,
  maxDate,
  defaultSelected,
  inputComponent: InputSelect = DefaultSelect,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedValue, setSelected] = useState(defaultSelected?.value);
  const { isDesktop } = useDimension();

  const parsedDateRange: DateRangeType = isDateStringRange(dateRange, format)
    ? [
        parse(dateRange[0], format, new Date()),
        parse(dateRange[1], format, new Date()),
      ]
    : [null, null];

  const handlePredefinedChange = (selectedValue: PredefinedDateRangeType) => {
    if (selectedValue === "custom") {
      setOpened(true);
    }

    setSelected(selectedValue);
  };

  useEffect(() => {
    if (selectedValue) {
      const selectedItem = predefinedRanges?.find(
        (v) => v.value === selectedValue,
      );
      onPredefinedChange?.(selectedValue);
      selectedItem && onChangeSelect(selectedItem);
    }
  }, [selectedValue]);

  const handleDateChange = (range: DateRangeType = [null, null]) => {
    const start = formatDate(range[0], format);
    const end = formatDate(range[1], format);

    if (start && end) {
      onChangeDate([start, end]);
      setOpened(false);
    }
  };

  const list = [...predefinedRanges, { label: "Выбрать", value: "custom" }];

  return (
    <>
      <InputSelect
        {...props}
        data={list}
        value={selectedValue}
        onChange={handlePredefinedChange}
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Выберите промежуток"
        className="justify-center break-normal whitespace-nowrap"
        size="lg"
        padding="xl"
      >
        <RangeCalendar
          allowSingleDateInRange
          minDate={minDate}
          maxDate={maxDate}
          locale="ru"
          nextMonthLabel="Следующий месяц"
          previousMonthLabel="Предыдущий месяц"
          amountOfMonths={!isDesktop ? 1 : 2}
          value={parsedDateRange}
          onChange={handleDateChange}
          size="md"
          fullWidth
          classNames={{
            calendarBase: "mb-[18px]",
          }}
        />
        <Space />
        <Group position="right">
          <Button onClick={() => setOpened(false)}>Закрыть</Button>
        </Group>
      </Modal>
    </>
  );
};
