import { LoadingOverlay } from "@mantine/core";
import { Flexbox, Row, Typography } from "src/components/Elements";
import { twMerge } from "tailwind-merge";
import React from "react";

import { SvgIconComponentType } from "@common/Types";
import { decimal } from "@common/Utils";

type Props = {
  label: string;
  value: number | undefined;
  icon: SvgIconComponentType;
  counter: string;
  decimalCut?: number;
  className?: string;
  loading?: boolean;
};
export const SummaryCard: React.FC<Props> = ({
  label,
  value,
  icon: Icon,
  counter,
  decimalCut,
  className,
  loading,
}) => {
  return (
    <Flexbox
      flexDirection="flex-col"
      bgColor="neutral-0"
      className={twMerge(
        "relative shadow rounded-lg w-full p-[24px] gap-[4px]",
        className,
      )}
      as="dl"
    >
      <LoadingOverlay visible={!!loading} />

      <div>
        <Icon width={24} height={24} color={"neutral-text-500"} />
      </div>

      <Typography
        variant="body-2-a"
        color="neutral-500"
        className="font-medium"
        as="dt"
      >
        {label}
      </Typography>

      <Row className="gap-[8px]" as="dd">
        <Typography
          variant="heading-1-a"
          color="neutral-900"
          className="font-bold"
        >
          {(value && decimal(value, decimalCut)) || 0}
        </Typography>

        <Typography
          variant="heading-1-a"
          color="neutral-900"
          className="font-bold"
        >
          {counter}
        </Typography>
      </Row>
    </Flexbox>
  );
};
