import { isEmpty } from "radash";
import { FAQAnswer } from "src/components/Shared";
import React from "react";

import { CurrentDataType, QuestionType } from "@common/Types";

import FAQQuestions from "./questions";

type Props = {
  data: QuestionType[];
  faqPath: string;
  onClickGo: (segment: string) => void;
};

const getItemData = (data: QuestionType[], segment: string) => {
  return data.find((item) => item.path === segment);
};

const getDataByPath = ({ faqPath, data }: Pick<Props, "data" | "faqPath">) => {
  if (!faqPath) {
    return {
      question: "Частые вопросы",
      questions: data,
      answer: "",
    };
  }

  const segments = faqPath
    .split("/")
    .filter((path: string) => !!path)
    .map((path: string) => "/" + path);

  const result = segments.reduce(
    (acc, segment, index) => {
      if (index === 0) {
        acc = getItemData(data, segment);
        return acc;
      }
      if (acc && acc?.questions) {
        acc = getItemData(acc.questions, segment);
      }
      return acc;
    },
    {} as QuestionType | undefined,
  );

  return result as CurrentDataType;
};

export const FAQPageBody = ({ data, faqPath, onClickGo }: Props) => {
  const currentData = getDataByPath({
    faqPath: faqPath,
    data: data,
  });

  if (isEmpty(currentData)) return null;

  return currentData.questions ? (
    <FAQQuestions data={currentData.questions} onClick={onClickGo} />
  ) : (
    <FAQAnswer markdown={currentData.answer} question={currentData?.question} />
  );
};
