import { ChartData, ChartOptions } from "chart.js";
import { Col, Loader, Row, Typography } from "src/components/Elements";
import React from "react";
import { Doughnut } from "react-chartjs-2";

import { StatusStatisticsTotalResponse } from "@common/Types";
import { formatSecondsToHoursAndMinutes } from "@common/Utils";

const STATUS = {
  online: { color: "#A3E9C1", label: "На линии" },
  offline: { color: "#F8ADAD", label: "Оффлайн" },
  busy: { color: "#FFD57A", label: "Занят" },
  on_ride: { color: "#CFF8FE", label: "В пути" },
};

type Props = {
  data?: StatusStatisticsTotalResponse;
  title: string;
  isLoading: boolean;
};

export const DoughnutStatistics = ({ data, title, isLoading }: Props) => {
  const statuses = React.useMemo(() => {
    const result = [];

    if (!data?.statuses) return [];

    for (const item of data?.statuses) {
      const selected = STATUS[item.status as keyof typeof STATUS];

      if (selected) {
        result.push({ ...selected, ...item });
      }
    }
    return result;
  }, [data]);

  const datasets: ChartData<"doughnut"> = {
    labels: statuses.map(
      ({ label, duration }) =>
        `${label}: ${formatSecondsToHoursAndMinutes(duration)}`,
    ),
    datasets: [
      {
        data: statuses.map(({ duration }) => Math.floor(duration / 3600)),
        backgroundColor: statuses.map(({ color }) => color),
        hoverOffset: 4,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => context.label || "",
        },
        bodyFont: {
          size: 14,
          family: "inter, sans-serif",
          weight: "400",
        },
        titleFont: {
          size: 14,
          family: "inter, sans-serif",
          weight: "400",
        },
      },
    },
  };

  return (
    <Loader loading={isLoading} height={268}>
      <Col className="p-4 bg-neutral-bg-0 shadow-new rounded-[8px] w-full lg:max-h-full xl:max-h-[268px]">
        <Typography
          variant="body-1-b"
          color="neutral-800"
          className="w-full font-bold border-b pb-4 mb-4 border-b-neutral-border-100"
        >
          {title}
        </Typography>

        {!statuses.length && (
          <Typography color="neutral-600">
            За выбранные период нет данных
          </Typography>
        )}

        <Row
          className="w-full lg:items-center xl:items-start lg:flex-col xl:flex-row lg:gap-3"
          justifyContent="justify-between"
          alignItems="items-start"
        >
          <Col
            as="ul"
            className="min-w-[300px] max-w-96 w-full gap-4"
            alignItems="items-center"
          >
            {statuses.map(({ label, color, duration, percentage }) => (
              <Row
                as="li"
                key={label}
                justifyContent="justify-between"
                className="w-full gap-2"
              >
                <Typography
                  as="div"
                  variant="body-2-a"
                  color="neutral-600"
                  className="font-normal flex flex-row items-center gap-4 w-28"
                >
                  <span
                    className="block w-2 h-2 rounded-[50%]"
                    style={{ backgroundColor: color }}
                  />
                  {label}
                </Typography>
                <Typography
                  variant="body-2-a"
                  color="neutral-600"
                  className="font-normal"
                >
                  {formatSecondsToHoursAndMinutes(duration)}
                </Typography>
                <Typography
                  variant="body-2-a"
                  color="neutral-600"
                  className="font-normal"
                >
                  {percentage}%
                </Typography>
              </Row>
            ))}
          </Col>
          <Row className="max-w-[180px] max-h-[180px]">
            <Doughnut options={options} data={datasets} />
          </Row>
        </Row>
      </Col>
    </Loader>
  );
};
