import { Checkbox as MantineCheckbox } from "@mantine/core";
import React, { ChangeEvent } from "react";

import { CheckboxProps } from "./types";
import { CheckboxClasses } from "./variants";

export const Checkbox = ({
  view = "brand",
  onChange,
  ...rest
}: CheckboxProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  return (
    <MantineCheckbox
      classNames={CheckboxClasses[view]}
      onChange={handleChange}
      {...rest}
    />
  );
};
