import { isArray } from "radash";
import { Col, Typography } from "src/components/Elements";
import React, { ReactNode } from "react";

import { CommonFieldParams } from "@common/Types";

type Params<T> = Pick<CommonFieldParams<T>, "name" | "show"> & {
  value: ReactNode;
};

type Props<T> = {
  params: Params<T>[] | Params<T>;
};

export const Description = <T,>({ params }: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map((param: Params<T>, index: number) => {
        const { name, value, show = true } = param;

        if (!show) return null;

        return (
          <Col key={`${String(name)}-${index}`} className="gap-1 flex-wrap">
            <Typography
              color="neutral-500"
              variant="body-2-b"
              as="dt"
              className="font-medium"
            >
              {name}
            </Typography>

            <Typography
              color="neutral-800"
              className="whitespace-normal"
              as="dd"
            >
              {value ?? "-"}
            </Typography>
          </Col>
        );
      })}
    </>
  );
};
