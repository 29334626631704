import clsx from "clsx";
import { Button, Col, Flexbox, Row, Typography } from "src/components/Elements";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import {
  useGetDriverApplications,
  useGetLandingDriverApplications,
  useGetParkApplications,
} from "@api/queries";
import { NAVIGATE_ROUTES } from "@common/Constants";
import { RoleType } from "@common/Types";
import { getUserRole } from "@common/Utils";
import { APP_COMPONENT } from "@configs";

import { useGetSidebarRoutes } from "./common/hooks";

type Props = {
  isOpen: boolean;
  setOpen: () => void;
};

export const DashboardSidebar = ({ isOpen, setOpen }: Props) => {
  const { pathname } = useLocation();
  const userRole = getUserRole() as RoleType;
  const acceptedRoutesList = useGetSidebarRoutes(userRole);

  const { data: parkApplications } = useGetParkApplications({
    skip: 0,
    limit: 14,
    filters: {
      status: "new",
    },
    enabled: isPathExist(NAVIGATE_ROUTES.PARKS_APPLICATIONS),
  });
  const { data: driverApplications } = useGetDriverApplications(
    {
      skip: 0,
      limit: 14,
      filters: {
        status: "new",
      },
    },
    {
      enabled: isPathExist(NAVIGATE_ROUTES.APPLICATIONS),
    },
  );
  const { data: landingDriverApplications } = useGetLandingDriverApplications(
    {
      skip: 0,
      limit: 14,
      filters: {
        is_read: "false",
      },
    },
    {
      enabled: isPathExist(NAVIGATE_ROUTES.APPLICATIONS),
    },
  );

  function isPathExist(curPath: string): boolean {
    const list = acceptedRoutesList.filter(({ routes }) =>
      routes.find(({ path }) => path === curPath),
    );

    return !!list.length;
  }

  const renderRightComponent = (path: string, hasPostfix: boolean) => {
    if (!hasPostfix) return null;

    const dataMapping = {
      [NAVIGATE_ROUTES.PARKS_APPLICATIONS]: parkApplications,
      [NAVIGATE_ROUTES.APPLICATIONS]: [
        driverApplications,
        landingDriverApplications,
      ].reduce(
        (acc, data) =>
          data
            ? {
                length: acc.length + data.length,
              }
            : acc,
        { length: 0 },
      ),
    };

    const dataList = dataMapping[path];
    const dataLength = dataList?.length;

    if (!dataLength) return null;

    return (
      <Typography
        variant="caption-a"
        color="neutral-0"
        className="rounded-[20px] bg-brand-bg-800 border border-neutral-border-0 flex items-center justify-center px-[6px] h-5 min-w-7"
      >
        {dataLength}
      </Typography>
    );
  };

  const getHomeByRole = () => {
    if (userRole === "parkadmin") {
      return NAVIGATE_ROUTES.RIDES;
    }

    return NAVIGATE_ROUTES.DASHBOARD;
  };

  const getPageTitleByRole = (title: string) => {
    if (userRole === "parkadmin") {
      return "";
    }

    return title;
  };

  return (
    <Flexbox
      bgColor="brand-1000"
      flexDirection="flex-col"
      className={clsx(
        "absolute z-40 left-0 top-0 gap-[8px] lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-[298px] flex-shrink-0 py-[24px] px-[20px] transition-transform duration-200 ease-in-out",
        {
          "translate-x-0": isOpen,
          "-translate-x-full": !isOpen,
        },
      )}
    >
      <Row className="gap-[10px]">
        <Button
          className="lg:hidden"
          view="transparent"
          onClick={() => setOpen()}
        >
          <svg
            className="w-[24px] h-[24px] fill-[#fff]"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"></path>
          </svg>
        </Button>

        <NavLink to={getHomeByRole()} className="mb-[8px] focus:outline-none">
          <APP_COMPONENT.LOGO />
        </NavLink>
      </Row>
      {acceptedRoutesList.map(({ title, routes }) => {
        if (!routes.length) return null;

        const pageTitle = getPageTitleByRole(title);

        return (
          <Col key={title} className="w-full">
            <Typography
              as="h3"
              variant={"caption-a"}
              color="neutral-0"
              className="w-full h-[32px] py-[8px] px-[12px] uppercase"
            >
              {pageTitle}
            </Typography>
            {routes.map(({ path, label, icon: Icon, rightComponent }) => {
              const isActive = pathname.includes(path);

              const displayLabel =
                typeof label === "function" ? label(userRole as string) : label;

              return (
                <NavLink
                  key={path}
                  to={path}
                  className={clsx(
                    "w-full h-auto p-[12px] neutral-text-300 rounded-[8px] focus:outline-none",
                    {
                      "bg-brand-bg-900": isActive,
                    },
                  )}
                >
                  <Row className="gap-[12px]" alignItems="items-center">
                    <div className="min-w-[24px]">
                      <Icon />
                    </div>
                    <Typography
                      variant={"body-2-a"}
                      className="w-full text-[#fff]"
                    >
                      {displayLabel}
                    </Typography>
                    {renderRightComponent(path, rightComponent)}
                  </Row>
                </NavLink>
              );
            })}
          </Col>
        );
      })}
    </Flexbox>
  );
};
