import clsx from "clsx";
import { Flexbox } from "src/components/Elements";
import { Contacts } from "src/components/Shared";
import React, { useEffect } from "react";

import { IS_MOTOR, IS_NAVI } from "@common/Constants";
import { AmplitudeSetup } from "@common/Providers";
import { APP_COMPONENT } from "@configs";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const LandingLayout: React.FC<Props> = ({ children, className }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flexbox
      flexDirection="flex-col"
      className={"min-h-[100vh]"}
      alignItems="items-stretch"
      justifyContent="justify-between"
    >
      {IS_MOTOR && <AmplitudeSetup />}
      <APP_COMPONENT.LANDING_HEADER />
      <Flexbox
        flexDirection="flex-col"
        alignItems="items-center"
        as="main"
        className={clsx(
          {
            "bg-dark-500": IS_NAVI,
          },
          className,
        )}
      >
        {children}
        {IS_NAVI && <Contacts />}
      </Flexbox>
      <APP_COMPONENT.LANDING_FOOTER />
    </Flexbox>
  );
};
