import { Geometry, Point } from "geojson";
import { Loader } from "src/components/Elements";
import React from "react";
import ReactMapGL, {
  GeolocateControl,
  Layer,
  NavigationControl,
  Source,
} from "react-map-gl";

import { fillLayer, lineLayer } from "./common/assets";
import { INITIAL_MAP_VIEWPORT } from "./common/constants";
import { buildZoneInitialValue } from "./common/utils/common";

type Props = {
  loading?: boolean;
  geometry?: Geometry | null;
  initialPoint?: Point | null;
};

const initialViewport = {
  ...INITIAL_MAP_VIEWPORT,
  zoom: 11,
};

export const GeoJSONMap = ({ loading, geometry, initialPoint }: Props) => {
  return (
    <div className="w-full h-full flex flex-col">
      <Loader loading={loading}>
        <ReactMapGL
          style={{ width: "100%", height: "100%", borderRadius: "0.375rem" }}
          initialViewState={buildZoneInitialValue(
            initialViewport,
            initialPoint,
          )}
          interactiveLayerIds={["fill", "line"]}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken="pk.eyJ1IjoienNlcmNxdyIsImEiOiJja3F3ZGFnbnkwa2poMzFxaGZ4bnBzeDE3In0.nlGFFWpud8L6b3hR8to8RQ"
        >
          <GeolocateControl position={"top-left"} />
          <NavigationControl position={"top-left"} />
          {geometry && (
            <Source type="geojson" data={geometry}>
              <Layer {...fillLayer} />
              <Layer {...lineLayer} />
            </Source>
          )}
        </ReactMapGL>
      </Loader>
    </div>
  );
};
