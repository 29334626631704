import { Row, Typography } from "src/components/Elements";
import React from "react";

type Props = {
  driversCount?: number;
};

export const MapCount: React.FC<Props> = ({ driversCount = 0 }) => {
  return (
    <Row className="absolute right-2 top-2 cursor-pointer control-panel gap-1 bg-neutral-bg-0 p-2 rounded-md">
      <Typography variant="caption-b" color="neutral-600">
        Всего:
      </Typography>
      <Typography
        variant="caption-b"
        className="font-medium"
        color="neutral-800"
      >
        {driversCount}
      </Typography>
    </Row>
  );
};
